import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";

import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions
} from "@material-ui/core";

import Challenge from "./Challenge";
import * as firebase from "firebase";
import fire from "../config/firebase";
import Loading from "./Loading";
const db = fire.firestore();
const functions = fire.functions("europe-west1");

const useStyles = makeStyles(
  theme => ({
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    card: {
      height: "100%",
      position: "relative"
    },
    media: {
      height: 140
    },
    marginTop: {
      marginTop: theme.spacing(5)
    }
  }),
  { withTheme: true }
);

function ChallengeList(props) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const createPackage = type => {
    setLoading(true);
    var notifcationUpdater = functions.httpsCallable("createTaskPackage");
    notifcationUpdater({
      eventId: props.eventId,
      type: type
    }).then(function(result) {
      console.log("result:", result);
      props.getEvent();
      setLoading(false);
    });
  };


  const createChallenge = async () => {
    console.log("click createChallenge");
    try {
      setLoading(true);

      db.collection("events")
        .doc(props.eventId)
        .collection("challenges")
        .add({
          users: [],
          photos: [],
          title: "",
          start: props.event.start,
          end: props.event.end,
          lastUpdated: new Date()
        })
        .then(documentReference => {
          // TODO: CHALLENGE ADDED

          console.log(`Added document with name: ${documentReference.id}`);
          console.log("fire.firestore", fire.firestore);
          db.collection("events")
            .doc(props.eventId)
            .update({
              challenges: firebase.firestore.FieldValue.arrayUnion(
                documentReference.id
              ),
              lastUpdated: new Date()
            })
            .then(function() {
              db.collection("events")
                .doc(props.eventId)
                .collection("challenges")
                .doc(documentReference.id)
                .update({
                  uid: documentReference.id,
                  lastUpdated: new Date()
                })
                .then(function() {
                  props.getEvent();
                  console.log("Document successfully updated!");
                })
                .catch(function(error) {
                  setLoading(false);
                  // The document probably doesn't exist.
                  console.error("Error updating challenge doc: ", error);
                });

              props.getEvent();
              console.log("Document successfully updated!");
            })
            .catch(function(error) {
              setLoading(false);
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .catch(function(error) {
          setLoading(false);
          // The document probably doesn't exist.
          console.error("Error adding challenge: ", error);
        });
    } catch (err) {
      console.log("createChallenge error",err);
      setLoading(false);
    }
  };
  if (loading) return <Loading />;
  return (
    <div>
      <Grid container spacing={4}>
        {props.event.challenges.map((challenge, idx) => {
          return (
            <Challenge
              key={idx}
              event={props.event}
              eventId={props.eventId}
              challengeId={challenge}
              before={props.event.end}
              after={props.event.start}
              refresh={() => props.getEvent()}
              isAdmin={props.user.uid === "O0OBFPSIW1USpDfGagabzOp6Pgh1"}
              orderSettings={props.orderSettings}
            />
          );
        })}

        {props.event.challenges.length > 0 &&
          props.event.challengesCount - props.event.challenges.length > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={require("../assets/img/bg-custom-1000x400.jpg")}
                  title="Custom"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Eine Aufgabe erstellen
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Du hast die Auswahl von lustigen, romantischen und kreativen
                    Aufgaben
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      createChallenge();
                    }}
                  >
                    Aufgabe erstellen (noch{" "}
                    {props.event.challengesCount -
                      props.event.challenges.length}{" "}
                    verfügbar)
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
      </Grid>

      {props.event.challenges.length === 0 && (
        <Grid container spacing={4} className={classes.marginTop}>

<Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={require("../assets/img/bg-random-1000x400.jpg")}
                title="Weeding"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Zufallspaket
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Ein Paket mit {props.event.challengesCount}{" "}
                  zufälligen Aufgaben. Du kannst die Aufgaben nachträglich
                  jederzeit löschen oder anpassen.
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    createPackage("all");
                  }}
                >
                  {props.event.challengesCount} Aufgaben erstellen
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={require("../assets/img/bg-business-1000x400.jpg")}
                title="Weeding"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Business-Paket
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Ein Paket mit {props.event.challengesCount}{" "}
                  Aufgaben für ein Firmenevent. Du kannst die Aufgaben nachträglich
                  jederzeit löschen oder anpassen.
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    createPackage("all");
                  }}
                >
                  {props.event.challengesCount} Aufgaben erstellen
                </Button>
              </CardActions>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={require("../assets/img/bg-birthday-1000x400.jpg")}
                title="Weeding"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Geburtstagspaket
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Ein Paket mit {props.event.challengesCount}{" "}
                  Geburtstagsaufgaben. Du kannst die Aufgaben nachträglich
                  jederzeit löschen oder anpassen.
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    createPackage("birthday");
                  }}
                >
                  {props.event.challengesCount} Aufgaben erstellen
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={require("../assets/img/bg-wedding-1000x400.jpg")}
                title="Wedding"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Hochzeitspaket
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Passende Aufgaben rund um die Hochzeit. Du kannst die Aufgaben
                  nachträglich jederzeit löschen oder anpassen.
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    createPackage("wedding");
                  }}
                >
                  {props.event.challengesCount} Aufgaben erstellen
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={require("../assets/img/bg-custom-1000x400.jpg")}
                title="Custom"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Eine Aufgabe erstellen
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Du hast die Auswahl von lustigen, romantischen und kreativen
                  Aufgaben
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    createChallenge();
                  }}
                >
                  Aufgabe erstellen (noch{" "}
                  {props.event.challengesCount - props.event.challenges.length}{" "}
                  verfügbar)
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
export default ChallengeList;
