import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 40,
    padding: 20,
    borderTopColor: "#eaeaea",
    borderTopWidth: 1,
    borderTopStyle: "solid"
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    color: "#818181"
  },
  left: {
    width: 200
  },
  right: {
    textAlign: "right"
  },
  button: {
    marginRight: 20
  }
}));

function Header(props) {
  const classes = useStyles();

  function logout() {
 
    props.signOut();
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            VODO® - ALL COPYRIGHT RESERVED 2019
          </Typography>
        </Grid>
        <Grid className={classes.right} item xs={12} md={6}>
          <Button
            size="small"
            variant="outlined"
            href="https://vodo.app/faq/"
            target="_blank"
            className={classes.button}
          >
            FAQ
          </Button>
          <Button
            size="small"
            variant="outlined"
            href="https://vodo.app/contact/"
            target="_blank"
            className={classes.button}
          >
            Kontakt
          </Button>
          {props.user && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => logout()}
              className={classes.button}
            >
              Abmelden
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;
