import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";

import {
  Typography,
  IconButton,
  Button,
  Card,
  TextField,
  CardContent,
  Grid
} from "@material-ui/core";

import clsx from "clsx";
import CreateIcon from "@material-ui/icons/Create";

import fire from "../config/firebase";
const db = fire.firestore();
const functions = fire.functions("europe-west1");

const useStyles = makeStyles(
  theme => ({
    titleBar: {
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      width: "100%",
      // backgroundImage: `url(${gradient})`,
      // backgroundRepeat: "repeat",
      // backgroundPosition: "center top",
      textAlign: "center"
      // backgroundSize: "100% 100%"
    },
    titleCard: {
      paddingBottom: theme.spacing(2)
    },
    card: {
      height: "100%",
      position: "relative",
      marginBottom: theme.spacing(3)
    },
    titleForm: {
      // backgroundColor: "white",
      // boxShadow: "inset 0px 6px 4px -2px #9E9E9E",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20
    },
    titleBarText: {
      // color: "white",
      fontWeight: "800",
      cursor: "pointer",
      fontSize: theme.typography.pxToRem(15),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(22)
      }
    },
    titleBarIcon: {
      color: "white",
      padding: 5,
      fontSize: 12
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  }),
  { withTheme: true }
);

function Title(props) {
  const classes = useStyles();

  const [expandedTitle, setExpandedTitle] = React.useState(false);
  const [title, setTitle] = React.useState(props.event.title);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {});

  function handleTitleClick() {
    setExpandedTitle(!expandedTitle);
  }

  const titleFormHandler = async () => {
    try {
      setError("");
      setLoading(true);
      db.collection("events")
        .doc(props.match.params.eventId)
        .update({
          title: title,
          lastUpdated: new Date()
        })
        .then(function() {
          props.getEvent();

          // getEvent();

          //setLoading(false);
          var notifcationUpdater = functions.httpsCallable(
            "notificationUpdater"
          );
          notifcationUpdater({
            eventId: props.match.params.eventId,
            action: "event_updated"
          }).then(function(result) {});
          setExpandedTitle(false);
        })
        .catch(function(error) {
          setLoading(false);
          setExpandedTitle(false);
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    } catch (err) {
      setExpandedTitle(false);
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleBar}>
            <div className={classes.titleBarText} onClick={handleTitleClick}>
              {props.event.title}
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expandedTitle
                })}
                onClick={handleTitleClick}
                aria-expanded={expandedTitle}
                aria-label="Bearbeiten"
              >
                <CreateIcon />
              </IconButton>
            </div>

            {expandedTitle && (
              <Card className={classes.titleCard}>
                <div className={classes.titleForm}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      titleFormHandler();
                    }}
                    className={classes.form}
                    noValidate
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="title"
                      label="Titel der Veranstaltung"
                      name="title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={loading}
                    >
                      {loading ? "Lade..." : "Änderung speichern"}
                    </Button>
                    {error && (
                      <div className={classes.alert}>
                        <Typography>{error}</Typography>
                      </div>
                    )}
                  </form>
                </div>
              </Card>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default Title;
