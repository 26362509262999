import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";

import {

  Typography,
  Button,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";


const useStyles = makeStyles(
  theme => ({
    card: {
      height: "100%",
      position: "relative"
    },
    codeLink: {
      wordBreak: "break-all",
      fontSize: theme.typography.pxToRem(22),
      fontWeight: "800",
      color: theme.palette.text.primary
    },
    button: {
      marginTop: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    headline: {
      marginTop: theme.spacing(3)
    },
    inviteCode: {
      fontSize: theme.typography.pxToRem(22),
      fontWeight: "800",
      color: theme.palette.text.primary
    }
  }),
  { withTheme: true }
);

function InvitationCode(props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={8}>
      <Card className={classes.card}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Gäste via Link einladen
          </Typography>
          {props.event.dynamicLinkUrl && (
            <a
              href={props.event.dynamicLinkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.codeLink}
            >
              {props.event.dynamicLinkUrl || null}
            </a>
          )}

          <Typography color="textSecondary" className={classes.headline}>
            Gäste via Code einladen
          </Typography>

          <Typography color="textSecondary" className={classes.inviteCode}>
            {props.event.inviteCode}
          </Typography>
          <Typography color="textSecondary" className={classes.headline}>
            Vor Ort mit QR Code einladen
          </Typography>
          <Button
            variant="contained"
            href={`/code/${props.eventId}`}
            target="_blank"
            className={classes.button}
            color="primary"
          >
            <PrintIcon className={classes.leftIcon} />
            Ausdruck mit QR-Code
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default InvitationCode;
