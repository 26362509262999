import React, { useEffect } from "react";

import Select from "react-select";

import { makeStyles } from "@material-ui/core/styles";

import { taskPackage, taskGroups } from "../data/tasks";

let challenges = [],
  groupedOptions = [];


taskGroups.map(x => {
  challenges = [];

  taskPackage.map((y, index) => {
    if (y.category.includes(x.category)) {
      challenges.push({
        value: y.value,
        label: y.title.de
      });
      return y;
    }
    return true;
  });

  groupedOptions.push({ label: x.label, options: challenges });
  return true;
});


const useStyles = makeStyles(theme => ({
  root: {
    height: 40,
    flexGrow: 1,
    "& div": {
      zIndex: 1
    }
  },
  container: {
    position: "relative"
  },
  groupStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  groupBadgeStyles: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
  }
}));

export default function IntegrationAutosuggest(props) {
  const classes = useStyles();

  const formatGroupLabel = data => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {}, []);
  return (
    <div className={classes.root}>
      <Select
        menuPortalTarget={document.querySelector("body")}
        defaultValue={{ value: props.titleId, label: props.title }}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        onChange={(inputValue, optionLabel) =>
          props.onChange(inputValue, optionLabel)
        }
      />
    </div>
  );
}
