import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import moment from "moment";
import "moment/locale/de";

import { Container, Typography, Grid } from "@material-ui/core";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Box from "@material-ui/core/Box";

import Loading from "./Loading";

import EventDetailTime from "./EventDetailTime";
import InvitationTime from "./InvitationTime";
import InvitationCode from "./InvitationCode";
import ChallengeList from "./ChallengeList";
import Title from "./Title";
import fire from "../config/firebase";
import gradient from "../assets/img/header-gradient-2.png";

const db = fire.firestore();

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      maxWidth: 1200,
      backgroundColor: theme.palette.background.paper
    },
    tabs: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0px 6px 4px -2px #9E9E9E",
      backgroundImage: `url(${gradient})`,
      backgroundRepeat: "repeat",
      backgroundPosition: "center top",
      backgroundSize: "100% 100%"
    },
    tab: {
      color: "white",
      marginLeft: 5,
      marginRight: 5,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: "800",
      [theme.breakpoints.up("sm")]: {
        marginLeft: 15,
        marginRight: 15,
        fontSize: theme.typography.pxToRem(22)
      }
    },
    indicator: {
      backgroundColor: "white"
    }
  }),
  { withTheme: true }
);

function Event(props) {
  const classes = useStyles();

  moment.locale("de");
  const [event, setEvent] = React.useState();
  const [orderSettings, setOrderSettings] = React.useState();
  const [loading, setLoading] = React.useState(true);
  // const [error, setError] = React.useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (typeof event === "undefined") {
      getEvent();
    }
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  function getEvent() {
    db.collection("events")
      .doc(props.match.params.eventId)
      .get()
      .then(snapshot => {
        setEvent(snapshot.data());
        // const data = snapshot.data();
        // setTitle(data.title);
      });
    db.collection("events")
      .doc(props.match.params.eventId)
      .collection("settings")
      .doc("order")
      .get()
      .then(snapshot => {
        setOrderSettings(snapshot.data());
        setLoading(false);
      });
  }

  if (loading) return <Loading />;
  console.log("orderSettings.setCustomTimes", !orderSettings.setCustomTimes);
  return (
    // <Container component="main" maxWidth="xl">
    <div>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        aria-label="Event Menu"
        centered={true}
        classes={{
          indicator: classes.indicator
        }}
      >
        <Tab wrapped className={classes.tab} label="Event" {...a11yProps(0)} />
        <Tab wrapped className={classes.tab} label="Gäste" {...a11yProps(1)} />
        <Tab
          wrapped
          className={classes.tab}
          // label={<Badge className={classes.padding} color="primary" badgeContent={event.challengesCount}>Aufgaben</Badge>}
          label="Aufgaben"
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Container maxWidth="lg">
          <Title {...props} event={event} getEvent={() => getEvent()} />
          <Grid container spacing={4}>
            <EventDetailTime
              title={"🚀 Veranstaltung beginnt"}
              eventId={props.match.params.eventId}
              event={event}
              date={event ? event.start : null}
              field="start"
              before={event.end}
              after={false}
              disabled={
                props.user.uid === "O0OBFPSIW1USpDfGagabzOp6Pgh1"
                  ? false
                  : !orderSettings.setCustomTimes
                  ? true
                  : event && event.users && event.users.length > 0
                  ? event.start
                  : false
              }
              duration={false}
              refreshEvent={() => getEvent()}
            />

            <EventDetailTime
              title={"🏁 Veranstaltung endet"}
              eventId={props.match.params.eventId}
              event={event}
              date={event ? event.end : null}
              field="end"
              before={event.voting}
              after={event.start}
              disabled={
                props.user.uid === "O0OBFPSIW1USpDfGagabzOp6Pgh1"
                  ? false
                  : !orderSettings.setCustomTimes
                  ? true
                  : event && event.users && event.users.length > 0
                  ? event.start
                  : false
              }
              duration={event.start}
              refreshEvent={() => getEvent()}
            />
            <EventDetailTime
              title={"❤️ Abstimmung bis"}
              eventId={props.match.params.eventId}
              date={event ? event.voting : null}
              field="voting"
              before={false}
              after={event.end}
              disabled={
                props.user.uid === "O0OBFPSIW1USpDfGagabzOp6Pgh1"
                  ? false
                  : !orderSettings.setCustomTimes
                  ? true
                  : event && event.users && event.users.length > 0
                  ? event.start
                  : false
              }
              duration={event.end}
              refreshEvent={() => getEvent()}
            />
          </Grid>
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <InvitationTime
              eventId={props.match.params.eventId}
              orderSettings={orderSettings}
              invitationCode={event.inviteCode || false}
              before={event.end}
              disabled={event.start}
              duration={event.start}
              event={event}
            />
            <InvitationCode
              eventId={props.match.params.eventId}
              invitationCode={event.inviteCode || false}
              event={event}
            />
          </Grid>
        </Container>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Container maxWidth="lg">
          <ChallengeList
            eventId={props.match.params.eventId}
            event={event}
            orderSettings={orderSettings}
            user={props.user}
            getEvent={() => getEvent()}
          />
        </Container>
      </TabPanel>
    </div>
  );
}
export default Event;
