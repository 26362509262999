const taskPackage = [
  {
    title: { de: "Romantisches Kuss Foto" },
    value: "romantic_kiss",
    category: ["all"]
  },
  {
    title: { de: "Kreatives Foto vor dem Eingang" },
    value: "creative_frontdoor",
    category: ["all"]
  },
  {
    title: { de: "Lustiges Bild mit 4 Personen" },
    value: "funny_4_persons",
    category: ["all"]
  },
  {
    title: { de: "Foto beim Anstoßen" },
    value: "cheers",
    category: ["all"]
  },
  {
    title: { de: "Foto von einem außergewöhnlichen Tanz" },
    value: "spectacular_dance",
    category: ["all"]
  },
  {
    title: { de: "Selfie mit dem Geburtstagskind" },
    value: "selfie_birthdaychild",
    category: ["birthday"]
  },
  {
    title: { de: "Selfie mit dem Hochzeitspaar" },
    value: "selfie_wedding_couple",
    category: ["wedding"]
  },
  {
    title: { de: "Foto vom Gastgeber beim Lachen" },
    value: "host_laughing",
    category: ["all"]
  },
  {
    title: { de: "Foto von einem lachenden Gast" },
    value: "guest_laughing",
    category: ["all"]
  },
  {
    title: { de: "Jemand der nicht ganz bei der Sache ist" },
    value: "not_stay_concentrated",
    category: ["all"]
  },
  {
    title: { de: "Eine Männergruppe" },
    value: "group_men",
    category: ["all"]
  },
  {
    title: { de: "Eine Frauengruppe" },
    value: "group_women",
    category: ["all"]
  },
  {
    title: { de: "Eine kleine Peinlichkeit" },
    value: "embarrassment",
    category: ["all"]
  },
  {
    title: { de: "Eine lustige Grimasse" },
    value: "funny_grimace",
    category: ["all"]
  },
  {
    title: { de: "Ein Bild von dir im Einklang mit der Deko" },
    value: "self_decoration",
    category: ["all"]
  },
  {
    title: { de: "Ein Foto vom Hochzeitskuchen" },
    value: "wedding_cake",
    category: ["wedding"]
  },
  {
    title: { de: "Selfie mit deinem Geschenk" },
    value: "selfie_present",
    category: ["birthday"]
  },
  {
    title: { de: "Ein Foto vom Essen" },
    value: "food",
    category: ["all"]
  },
  {
    title: { de: "Eine Person beim Sprung" },
    value: "person_jump",
    category: ["all"]
  }
];

const taskGroups = [
  {
    label: "Allgemein",
    category: "all"
  },
  {
    label: "Geburtstag",
    category: "birthday"
  },
  {
    label: "Hochzeit",
    category: "wedding"
  }
];

module.exports = {
  taskPackage,
  taskGroups
};
