import React, {useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";


import moment from "moment";
import "moment/locale/de";
import "firebase/firestore";

import {
  Container,
  CssBaseline,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  IconButton
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";

import fire from "../config/firebase";

import Loading from "./Loading";
const db = fire.firestore();

const useStyles = makeStyles(theme => ({
  headline: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

function Eventlist(props) {
  // console.log("evnetlist props", props);
  if (props.events) {
    return (
      <List component="nav" aria-label="Deine Veranstaltungen">
        {props.events.map((event, idx) => {
          return (
            <div key={idx}>
              <ListItem>
                <ListItemText primary={event.title} secondary={moment(new Date(event.start.seconds * 1000)).format("dd, DD.MM.YYYY")} />
                <ListItemSecondaryAction
                  onClick={() => props.history.push(`/manage/${event.uid}`)}
                >
                  <IconButton edge="end" aria-label="Settings">
                    <SettingsIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {props.events.length - 1 > idx && <Divider />}
            </div>
          );
        })}
      </List>
    );
  } else {
    return <Typography>Keine Veranstaltungen</Typography>;
  }
}

function Manager(props) {
  const classes = useStyles();
  //console.log("manager props",props);
  const [events, setEvents] = React.useState();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (typeof events === "undefined" && props.user) {
      getEvents(props.user);
    }
  });

  function getEvents(user) {
    if(user.uid === "O0OBFPSIW1USpDfGagabzOp6Pgh1") {
      db.collection("events")
      .get()
      .then(snapshot => {
        var events = [];
        snapshot.forEach(function(snapshot) {
          events.push(Object.assign(snapshot.data(), { uid: snapshot.id }));
        });
        setEvents(events);
        setLoading(false);
      });
    } else {
      db.collection("events")
      .where("creatorId", "==", user.uid)
      .get()
      .then(snapshot => {
        var events = [];
        snapshot.forEach(function(snapshot) {
          events.push(Object.assign(snapshot.data(), { uid: snapshot.id }));
        });
        setEvents(events);
        setLoading(false);
      });
    }
    
  }

  if (loading) return <Loading />;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography variant="h4" className={classes.headline}>
        Deine Veranstaltungen
      </Typography>
      <div className={classes.root}>
        <Eventlist events={events} history={props.history} />
      </div>
    </Container>
  );
}
export default Manager;
