import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/de";

import {
  Typography,
  IconButton,
  Button,
  Collapse,
  Grid,
  Card,
  CardActions,
  CardContent
} from "@material-ui/core";

import { KeyboardDateTimePicker } from "@material-ui/pickers";

import clsx from "clsx";
import CreateIcon from "@material-ui/icons/Create";

import Loading from "./Loading";

import fire from "../config/firebase";
const db = fire.firestore();

const useStyles = makeStyles(
  theme => ({
    headline: {
      marginBottom: theme.spacing(3)
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    card: {
      height: "100%",
      position: "relative"
    },
    cardContent: {
      paddingRight: 60
    },
    cardAction: {
      position: "absolute",
      right: 0,
      top: 0
    },
    root: {
      width: "100%",
      maxWidth: 1200,
      backgroundColor: theme.palette.background.paper
    },
    challengeTitle: {
      margin: theme.spacing(1, 0, 1),
      fontSize: 26
    },
    section: {
      margin: theme.spacing(2, 0, 2)
    },
    label: {
      margin: theme.spacing(3, 0, 2)
    },
    input: {
      width: "100%"
    },

    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2)
    },
    alert: {
      borderRadius: 4,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: "#D5817C",
      color: "#ffffff",
      fontSize: 12
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    largeTitle: {
      margin: theme.spacing(1, 0, 1),
      fontSize: 22,
      fontWeight: "800"
    },
    secondTitle: {
      marginTop:theme.spacing(3),
    }
  }),
  { withTheme: true }
);

function Invitation(props) {
  const classes = useStyles();

  moment.locale("de");



  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const [expanded, setExpanded] = React.useState(false);
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function onChangeStart(timestamp) {
    setError("");
    if (moment(timestamp).isAfter(before)) {
      setError(
        "Start darf nicht nach " +
          moment(before).format("HH:mm") +
          " Uhr liegen"
      );
    } else if (moment(timestamp).isAfter(timeEnd)) {
      setError("Ende darf nicht vor Beginn liegen");
    }

    //TODO start vs end!

    if (!error) {
      try {
        setStart(timestamp);
      } catch (err) {
        setError("Fehlerhafte Eingabe");
      }
    }
  }
  function onChangeEnd(timestamp) {
    setError("");
    if (moment(timestamp).isAfter(before)) {
      setError(
        "Ende darf nicht vor dem Start um " +
          moment(before).format("HH:mm") +
          " Uhr liegen"
      );
    } else if (moment(timeStart).isAfter(timestamp)) {
      setError("Das Ende darf nicht vor dem Start liegen");
    }

    //TODO start vs end!

    if (!error) {
      try {
        setEnd(timestamp);
      } catch (err) {
        setError("Fehlerhafte Eingabe");
      }
    }
  }

  useEffect(() => {
    if (typeof start === "undefined" && props.invitationCode) {
      getInvitation();
    }
  });

  const updateHandler = async () => {
    try {
      setError("");
      setLoading(true);

      if (!start) {
        setError("Start der Einladungzeit darf nicht leer sein");
        setLoading(false);
      } else if (!end) {
        setError("Ende der Einladungzeit darf nicht leer sein");
        setLoading(false);
      } else {
        db.collection("invitations")
          .doc(props.invitationCode)
          .update({
            start: start,
            end: end,
            lastUpdated: new Date()
          })
          .then(function() {
            //getChallenge();
            setLoading(false);
            setExpanded(false);
            // console.log("Document successfully updated!");
          })
          .catch(function(error) {
            setLoading(false);
            setExpanded(false);
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    } catch (err) {
      console.error("error while updating", err);

      setLoading(false);
    }
  };

  function getInvitation() {
    db.collection("invitations")
      .doc(props.invitationCode)
      .get()
      .then(snapshot => {
        const data = snapshot.data();
     
        setStart(data.start);
        setEnd(data.end);
        setLoading(false);
      });
  }

  if (loading) return <Loading />;

  const timeStart = start
      ? start.seconds
        ? new Date(start.seconds * 1000)
        : start
      : null,
    timeEnd = end ? (end.seconds ? new Date(end.seconds * 1000) : end) : null;

  const duration =
    timeStart && timeEnd
      ? moment
          .duration(moment(timeStart).diff(moment(timeEnd), "milliseconds"))
          .locale("de")
          .humanize()
      : false;

  const before = props.before
    ? props.before.seconds
      ? new Date(props.before.seconds * 1000)
      : props.before
    : null;

  // const after = props.after
  //   ? props.after.seconds
  //     ? new Date(props.after.seconds * 1000)
  //     : props.after
  //   : null;

  const stateText = moment().isBefore(timeStart)
    ? "⏰ Einladung startet bald"
    : moment().isBefore(timeEnd)
    ? "✅ Einladung aktiv"
    : "❌ Einladung vorbei";



  if (start) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              <Moment format="ddd, DD.MM.YYYY">{timeStart}</Moment>
              {" ab "}
              <Moment format="HH:mm">{timeStart}</Moment>
              {" Uhr"}
            </Typography>

            <Typography
              variant="h4"
              component="h2"
              className={classes.largeTitle}
            >
              {stateText}
            </Typography>
            {duration && (
              <Typography className={classes.pos} color="textSecondary">
                {"⏱ Einladungszeitraum läuft "}
                {duration}
              </Typography>
            )}
            <Typography
              className={classes.secondTitle}
              color="textSecondary"
              gutterBottom
            >
              {"Einladung angenommen"}
            </Typography>

            <Typography
              variant="h4"
              component="h2"
              className={classes.largeTitle}
            >
              {props.event.users.length} / {props.event.userLimit}
            </Typography>
          </CardContent>
          {props.orderSettings.setInvitationTime && (
            <div>
              <CardActions className={classes.cardAction}>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Bearbeiten"
                >
                  <CreateIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.label}>
                      Einladung startet
                    </Typography>
                    <KeyboardDateTimePicker
                      className={classes.input}
                      ampm={false}
                      inputVariant={"outlined"}
                      format={"DD.MM.YYYY HH:mm"}
                      minutesStep={5}
                      value={timeStart}
                      onChange={timestamp => {
                        onChangeStart(timestamp.toDate());
                      }}
                      maxDate={before}
                    />
                  </div>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.label}>
                      Einladung beendet
                    </Typography>
                    <KeyboardDateTimePicker
                      className={classes.input}
                      ampm={false}
                      inputVariant={"outlined"}
                      format={"DD.MM.YYYY HH:mm"}
                      minutesStep={5}
                      value={timeEnd}
                      onChange={timestamp => onChangeEnd(timestamp.toDate())}
                      maxDate={before}
                      minDate={timeStart}
                    />
                  </div>

                  <div className={classes.section}>
                    {error ? (
                      <div className={classes.alert}>
                        <Typography>{error}</Typography>
                      </div>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => updateHandler()}
                      >
                        Einladungszeit aktualisieren
                      </Button>
                    )}
                  </div>
                </CardContent>
              </Collapse>
            </div>
          )}
        </Card>
      </Grid>
    );
  } else {
    return null;
  }
}
export default Invitation;
