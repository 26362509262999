import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import moment from "moment";
import "moment/locale/de";
import QRCode from "qrcode.react";
import { Container, Typography } from "@material-ui/core";
import logo from "../assets/img/vodo-logo-round.png";

import Loading from "./Loading";
import fire from "../config/firebase";
const db = fire.firestore();

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      textAlign: "center"
    },
    logo: {
      height: 120,
      marginTop: 30,
      marginBottom: 20
    },
    title: {
      marginBottom: 40,
      fontSize: 52,
      fontWeight: 800
    },
    codeTitle: {
      fontSize: 24,

      marginBottom: 20,
      maxWidth: "40%",
      marginRight: "auto",
      marginLeft: "auto"
    },
    codeWrapper: {
      borderWidth: 1,
      borderColor: "#000",
      borderRadius: 20,
      padding: 30,
      borderStyle: "solid",
      marginBottom: 40,
      display: "inline-block"
    },
    codeText: {},
    codeQR: {
      display: "block",
      marginRight: "auto",
      marginLeft: "auto"
    }
  }),
  { withTheme: true }
);

function Code(props) {
  const classes = useStyles();

  moment.locale("de");
  const [event, setEvent] = React.useState();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (typeof event === "undefined") {
      getEvent();
    }
  });

  function getEvent() {
    db.collection("events")
      .doc(props.match.params.eventId)
      .get()
      .then(snapshot => {
        setEvent(snapshot.data());
        setLoading(false);
      });
  }

  if (loading) return <Loading />;
  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.wrapper}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <Typography variant="h2" className={classes.title}>
          {event.title}
        </Typography>
        <Typography variant="h2" className={classes.codeTitle}>
          Den Einladungscode in der kostenfreien Vodo-App eingeben oder einfach
          abscannen!
        </Typography>
        <div className={classes.codeWrapper}>
          <Typography variant="h2" className={classes.codeText}>
            {event.inviteCode}
          </Typography>
        </div>
        {event.dynamicLinkUrl && (
          <QRCode
            className={classes.codeQR}
            size={300}
            value={`https://invite.vodo.app/${event.dynamicLinkUrl}`}
          />
        )}
      </div>
    </Container>
  );
}
export default Code;
