import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../assets/img/vodo-logo-white.png";
import gradient from "../assets/img/header-gradient-2.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "yellow"
  },
  appbar: {
    // backgroundColor:"yellow",
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center top",
    backgroundSize: "100% 100%",
    boxShadow: "none"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  logo: {
    height: 40,
    marginRight: 20
  }
}));

function Header(props) {


  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="static">
        <Toolbar>
          <a href="/">
            <img src={logo} alt="Logo" className={classes.logo} />
          </a>
          <Typography variant="h6" className={classes.title}>
            Event-Manager
          </Typography>
          <div>
            {/* {props.user ? (
              <Typography variant="h6" className={classes.title}>
                {props.user.displayName}{" "}
                <Button color="inherit" onClick={() => logout()}>
                  <LogoutIcon />
                </Button>
              </Typography>
            ) : (
              <Button color="inherit" onClick={props.signInWithGoogle}>
                <LoginIcon />
              </Button>
            )} */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
