import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/de";

import {
  Typography,
  IconButton,
  Button,
  Collapse,
  Grid,
  Card,
  CardActions,
  CardContent
} from "@material-ui/core";

import { KeyboardDateTimePicker } from "@material-ui/pickers";

import clsx from "clsx";
import CreateIcon from "@material-ui/icons/Create";

import Loading from "./Loading";

import TaskSelect from "./TaskSelect";
import * as firebase from "firebase";
import fire from "../config/firebase";
const db = fire.firestore();
const functions = fire.functions("europe-west1");
const useStyles = makeStyles(
  theme => ({
    headline: {
      marginBottom: theme.spacing(3)
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    card: {
      height: "100%",
      position: "relative",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "white"
    },
    cardNotReady: {
      height: "100%",
      position: "relative",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#D5817C"
    },
    cardContent: {
      paddingRight: 60
    },
    cardAction: {
      position: "absolute",
      right: 0,
      top: 0
    },
    root: {
      width: "100%",
      maxWidth: 1200,
      backgroundColor: theme.palette.background.paper
    },
    challengeTitle: {
      // margin: theme.spacing(1, 0, 1),
      // fontSize: 26
      margin: theme.spacing(1, 0, 1),
      fontSize: 22,
      fontWeight: "800"
    },
    largeTitle: {
      margin: theme.spacing(1, 0, 1),
      fontSize: 22,
      fontWeight: "800"
    },
    section: {
      margin: theme.spacing(2, 0, 2)
    },
    label: {
      margin: theme.spacing(3, 0, 2)
    },
    input: {
      width: "100%"
    },

    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2)
    },
    alert: {
      borderRadius: 4,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: "#D5817C",
      color: "#ffffff",
      fontSize: 12
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  }),
  { withTheme: true }
);

function Challenge(props) {
  const classes = useStyles();

  moment.locale("de");

  

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [challenge, setChallenge] = React.useState();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function onChange(field, obj) {
  
    setError("");
    if (field === "start" || field === "end") {
      // timestamp test!
      if (moment(obj[field]).isAfter(before)) {
        setError(
          "Darf nicht nach " + moment(before).format("HH:mm") + " Uhr liegen"
        );
      } else if (moment(obj[field]).isBefore(after)) {
        setError(
          "Darf nicht vor " + moment(after).format("HH:mm") + " Uhr liegen"
        );
      }
    }

    if (!error) {
      try {
        setChallenge({ ...challenge, ...obj });
      } catch (err) {
        setError("Fehlerhafte Eingabe");
      }
      console.log("challenge after", challenge);
    }
  }

  useEffect(() => {
    if (typeof challenge === "undefined") {
      getChallenge();
    }
  });

  const resetPhotos = () => {
    try {
      setError("");
      setLoading(true);
      db.collection("events")
        .doc(props.eventId)
        .collection("challenges")
        .doc(props.challengeId)
        .collection("photos")
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            doc.ref.delete();
          });
        })
        .catch(function(error) {
          console.error("Error removing photos: ", error);
        });
    } catch (err) {
      console.error("error while updating", err);
      setLoading(false);
    }
    db.collection("events")
      .doc(props.eventId)
      .collection("challenges")
      .doc(props.challengeId)
      .set(
        {
          users: [],
          photos: []
        },
        { merge: true }
      );
    setLoading(false);
  };
  const removeChallenge = async () => {
    try {
      setError("");
      setLoading(true);
      db.collection("events")
        .doc(props.eventId)
        .collection("challenges")
        .doc(props.challengeId)
        .delete()
        .then(function() {
          console.log("Document successfully deleted!");

          // TODO: CHALLENGE DELETED

          db.collection("events")
            .doc(props.eventId)
            .update({
              challenges: firebase.firestore.FieldValue.arrayRemove(
                props.challengeId
              ),
              lastUpdated: new Date()
            })
            .then(function() {
              console.log("DONE");
              props.refresh();
              setLoading(false);
            });
        })
        .catch(function(error) {
          console.error("Error removing document: ", error);
        });
    } catch (err) {
      console.error("error while updating", err);

      setLoading(false);
    }
  };

  const updateHandler = async () => {
    //

    console.log("try to update", challenge, " props ", props);
    try {
      setError("");
      setLoading(true);

      if (!challenge.title) {
        setError("Aufgabe darf nicht leer sein");
        setLoading(false);
      } else if (!challenge.start) {
        setError("Start der Aufgabe darf nicht leer sein");
        setLoading(false);
      } else if (!challenge.end) {
        setError("Ende der Aufgabe darf nicht leer sein");
        setLoading(false);
      } else {
        // TODO: CHALLENGE UPDATED

        db.collection("events")
          .doc(props.eventId)
          .collection("challenges")
          .doc(props.challengeId)
          .update({
            title: challenge.title,
            start: challenge.start,
            end: challenge.end,
            titleId: challenge.titleId,
            lastUpdated: new Date()
          })
          .then(function() {
            getChallenge();
            //setLoading(false);

            // challenge.start
            // challenge.end

            //

            let challengeStart = challenge.start.seconds
              ? challenge.start.seconds
              : new Date(challenge.start).getTime() / 1000;
            let challengeEnd = challenge.end.seconds
              ? challenge.end.seconds
              : new Date(challenge.end).getTime() / 1000;

 

            let sameStart = false,
              sameEnd = false;
            if (challengeStart === props.event.start.seconds) {
              sameStart = true;
            }

            if (challengeEnd === props.event.end.seconds) {
              sameEnd = true;
            }

       

            var notifcationUpdater = functions.httpsCallable(
              "notificationUpdater"
            );

            let limited = false;

            if (!sameStart || !sameEnd) {
              if (!sameStart && !sameEnd) {
                limited = false;
              } else if (!sameStart) {
                limited = "start";
                notifcationUpdater({
                  eventId: props.eventId,
                  challengeId: props.challengeId,
                  action: "challenge_removed",
                  limited: "end"
                }).then(function(result) {
                
                });
              } else {
                limited = "end";
                notifcationUpdater({
                  eventId: props.eventId,
                  challengeId: props.challengeId,
                  action: "challenge_removed",
                  limited: "start"
                }).then(function(result) {
                  console.log("challenge_removed start:", result);
                });
              }

              notifcationUpdater({
                eventId: props.eventId,
                challengeId: props.challengeId,
                action: "challenge_updated",
                limited: limited
              }).then(function(result) {
           
              });
            } else {
              notifcationUpdater({
                eventId: props.eventId,
                challengeId: props.challengeId,
                action: "challenge_removed",
                limited: false
              }).then(function(result) {
             
              });
            }

            setExpanded(false);
      
          })
          .catch(function(error) {
            setLoading(false);
            setExpanded(false);
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    } catch (err) {
      console.error("error while updating", err);

      setLoading(false);
    }
  };

  function getChallenge() {

    db.collection("events")
      .doc(props.eventId)
      .collection("challenges")
      .doc(props.challengeId)
      .get()
      .then(snapshot => {
        const data = snapshot.data();
        setChallenge(data);
        setLoading(false);
      });
  }

  if (loading) return <Loading />;

  const timeStart =
      challenge && challenge.start
        ? challenge.start.seconds
          ? new Date(challenge.start.seconds * 1000)
          : challenge.start
        : null,
    timeEnd =
      challenge && challenge.end
        ? challenge.end.seconds
          ? new Date(challenge.end.seconds * 1000)
          : challenge.end
        : null;

  const duration =
    timeStart && timeEnd
      ? moment
          .duration(moment(timeStart).diff(moment(timeEnd), "milliseconds"))
          .locale("de")
          .humanize()
      : false;

  const before = props.before
    ? props.before.seconds
      ? new Date(props.before.seconds * 1000)
      : props.before
    : null;

  const after = props.after
    ? props.after.seconds
      ? new Date(props.after.seconds * 1000)
      : props.after
    : null;

  if (challenge) {
    return (
      <Grid item xs={12} sm={12} md={4}>
        <Card className={challenge.title ? classes.card : classes.cardNotReady}>
          {!expanded && (
            <CardContent className={classes.cardContent}>
              {challenge.start && props.orderSettings.setCustomTaskTimes && (
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  <Moment format="dd, DD.MM.YYYY">{timeStart}</Moment>
                  {" ab "}
                  <Moment format="HH:mm">{timeStart}</Moment>
                  {" Uhr"}
                </Typography>
              )}
              <Typography
                variant="h4"
                component="h2"
                className={classes.challengeTitle}
              >
                {challenge.title ? challenge.title : "⚠️ Aufgabe nicht bereit"}
              </Typography>

              {moment(timeEnd).isBefore() ? (
                <Typography className={classes.pos} color="textSecondary">
                  {"✅ Aufgabe vorbei "}
                </Typography>
              ) : (
                <div>
                  {moment(timeStart).isBefore() ? (
                    <div>
                      <Typography className={classes.pos} color="textSecondary">
                        {"⏱ Läuft noch "}{" "}
                        {moment
                          .duration(
                            moment().diff(moment(timeEnd), "milliseconds")
                          )
                          .locale("de")
                          .humanize()}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {duration && (
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {"⏱ Aufgabe läuft insgesamt "}
                          {duration}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* {challenge.users && (
                <Typography className={classes.pos} color="textSecondary">
                  {"📷 "}
                  {challenge.users.length}
                  {challenge.users.length === 1
                    ? " Gast teilgenommen"
                    : " Gäste teilgenommen"}
                </Typography>
              )} */}
            </CardContent>
          )}
          <CardActions className={classes.cardAction}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Bearbeiten"
            >
              <CreateIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <div className={classes.section}>
                <Typography variant="h5" className={classes.label}>
                  Aufgabe
                </Typography>
                <TaskSelect
                  className={classes.input}
                  title={challenge && challenge.title ? challenge.title : ""}
                  titleId={
                    challenge && challenge.titleId ? challenge.titleId : ""
                  }
                  onChange={data => {
                  
                    onChange("title", {
                      titleId: data.value,
                      title: data.label
                    });
                  }}
                />
            
              </div>
              {props.orderSettings.setCustomTaskTimes && (
                <div>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.label}>
                      Start der Aufgabe
                    </Typography>
                    <KeyboardDateTimePicker
                      className={classes.input}
                      ampm={false}
                      inputVariant={"outlined"}
                      format={"DD.MM.YYYY HH:mm"}
                      minutesStep={5}
                      value={timeStart}
                      onChange={timestamp => {
                        
                        onChange("start", { start: timestamp.toDate() });
                      }}
                      maxDate={before}
                      minDate={after}
                    />
                  </div>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.label}>
                      Ende der Aufgabe
                    </Typography>
                    <KeyboardDateTimePicker
                      className={classes.input}
                      ampm={false}
                      inputVariant={"outlined"}
                      format={"DD.MM.YYYY HH:mm"}
                      minutesStep={5}
                      value={timeEnd}
                      onChange={timestamp =>
                        onChange("end", { end: timestamp.toDate() })
                      }
                      maxDate={before}
                      minDate={after}
                    />
                  </div>
                </div>
              )}

              <div className={classes.section}>
                {error ? (
                  <div className={classes.alert}>
                    <Typography>{error}</Typography>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateHandler()}
                  >
                    Aufgabe aktualisieren
                  </Button>
                )}
              </div>
              <div className={classes.section}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => removeChallenge()}
                >
                  Aufgabe löschen
                </Button>
              </div>
              {props.isAdmin && (
                <div className={classes.section}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => resetPhotos()}
                  >
                    Alle Fotos löschen
                  </Button>
                </div>
              )}
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    );
  } else {
    return null;
  }
}
export default Challenge;
