import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";

import {
  Container,
  Button,
  TextField,
  Typography,
  Avatar,
  CssBaseline
} from "@material-ui/core";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import fire from "../config/firebase";

const useStyles = makeStyles(
  theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    alert: {
      borderRadius: 4,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: "#D5817C",
      color: "#ffffff",
      fontSize: 12
    }
  }),
  { withTheme: true }
);

function Login(props) {
  const classes = useStyles();

  useEffect(() => {
    //document.title = "test";
  });




  const [userEmail, setUserEmail] = React.useState(props.match.params.email);
  const [userPassword, setUserPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const authHandler = async () => {
    try {
      setError("");
      setLoading(true);
      fire
        .auth()
        .signInWithEmailAndPassword(userEmail, userPassword)
        .catch(function(error) {
          // Handle Errors here.

          setLoading(false);
          setError(
            "Leider gab es ein Problem bei der Anmeldung, versuche es erneut"
          );
          // ...
        });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}   onClick={() => props.signInWithGoogle()} >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anmelden
        </Typography>
        <form
          onSubmit={e => {
            e.preventDefault();
            authHandler();
          }}
          className={classes.form}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-Mail Adresse"
            name="email"
            autoComplete="email"
            autoFocus
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setUserPassword(e.target.value)}
            value={userPassword}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {loading ? "Lade..." : "Anmelden"}
          </Button>
          {error && (
            <div className={classes.alert}>
              <Typography>{error}</Typography>
            </div>
          )}

          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      {/* <Box mt={5}>
        <MadeWithLove />
      </Box> */}
    </Container>
  );
}

export default Login;
