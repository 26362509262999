import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    textAlign:"center",
    marginTop: 150
  },
  progress: {
    width: "150px"
  }
}));
 
function Loading(props) {
  const classes = useStyles();
  

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={50} className={classes.progress} />
    </div>
  );
}
export default Loading;
