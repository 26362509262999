
import * as firebase from "firebase/app";
import "firebase/firestore";


var firebaseConfig = {
  apiKey: "AIzaSyCLp9lImxurAMp2x5ancqxsd7reRSXFJUk",
  authDomain: "vodo-1.firebaseapp.com",
  databaseURL: "https://vodo-1.firebaseio.com",
  projectId: "vodo-1",
  storageBucket: "vodo-1.appspot.com",
  messagingSenderId: "880524316981",
  appId: "1:880524316981:web:e9373005158519a9"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
