import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import "firebase/functions";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/de";

import {
  Typography,
  IconButton,
  Button,
  Collapse,
  Grid,
  Card,
  CardActions,
  CardContent
} from "@material-ui/core";

import { KeyboardDateTimePicker } from "@material-ui/pickers";

import clsx from "clsx";
import CreateIcon from "@material-ui/icons/Create";

import Loading from "./Loading";

import fire from "../config/firebase";
const db = fire.firestore();
const functions = fire.functions("europe-west1");

const useStyles = makeStyles(
  theme => ({
    card: {
      height: "100%",
      position: "relative"
    },
    cardAction: {
      position: "absolute",
      right: 0,
      top: 0
    },
    root: {
      width: "100%",
      maxWidth: 1200,
      backgroundColor: theme.palette.background.paper
    },
    largeTitle: {
      margin: theme.spacing(1, 0, 1),
      fontSize: 22,
      fontWeight: "800"
    },
    section: {
      margin: theme.spacing(2, 0, 2)
    },

    input: {
      width: "100%"
    },

    alert: {
      borderRadius: 4,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: "#D5817C",
      color: "#ffffff",
      fontSize: 12
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  }),
  { withTheme: true }
);

function EventDetailTime(props) {
  const classes = useStyles();

  moment.locale("de");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const [date, setDate] = React.useState();

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function onChange(timestamp) {
    setError("");
    if (!timestamp) {
      setError("Darf nicht leer sein");
    } else if (moment(timestamp).isAfter(before)) {
      setError(
        "Darf nicht nach " + moment(before).format("HH:mm") + " Uhr liegen"
      );
    } else if (moment(timestamp).isBefore(after)) {
      setError(
        "Darf nicht vor " + moment(after).format("HH:mm") + " Uhr liegen"
      );
    } else {
      try {
        setDate(timestamp.toDate());
      } catch (err) {
        setError("Fehlerhafte Eingabe");
      }
    }
  }

  useEffect(() => {
    if (typeof date === "undefined") {
      setDate(props.date);
    }
  }, [date, props.date]);

  function updateChallenge(challengeId,field, data) {
    var notifcationUpdater = functions.httpsCallable("notificationUpdater");

    db.collection("events")
      .doc(props.eventId)
      .collection("challenges")
      .doc(challengeId)
      .update(data)
      .then(function() {
        // update notification
        notifcationUpdater({
          eventId: props.eventId,
          challengeId: challengeId,
          action: "challenge_updated",
          limited: field
        }).then(function(result) {
          console.log("notifcationUpdater success:", result);
        });
      });
  }

  const updateChallenges = field => {
    props.event.challenges.map((challenge, idx) => {
      console.log("challenge", challenge);
      updateChallenge(challenge,field, { [field]: date, lastUpdated: new Date() });
      return true;
    });
  };

  const updateHandler = async () => {
    try {
      setError("");
      setLoading(true);

      if (!date) {
        setError("Feld darf nicht leer sein");
        setLoading(false);
      } else {
        db.collection("events")
          .doc(props.eventId)
          .update({
            [props.field]: date,
            lastUpdated: new Date()
          })
          .then(function() {
            //getChallenge();
            // TODO : EVENT CHANGED!
            var notifcationUpdater = functions.httpsCallable(
              "notificationUpdater"
            );
            notifcationUpdater({
              eventId: props.eventId,
              action: "event_updated"
            }).then(function(result) {
              console.log("result:", result);
            });

            if (props.field === "start" || props.field === "end") {
              updateChallenges(props.field);
            }

            setLoading(false);
            setExpanded(false);
            props.refreshEvent();
          })
          .catch(function(error) {
            setLoading(false);
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    } catch (err) {
      console.error("error while updating", err);

      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  // console.log("EventDetailTime #1", props.title, " - after:", props.after);

  const timeDate = date
    ? date.seconds
      ? new Date(date.seconds * 1000)
      : date
    : null;

  // const disabled = props.disabled
  //   ? date
  //     ? props.disabled.seconds
  //       ? moment(new Date(props.disabled.seconds * 1000)).isAfter()
  //       : moment(date).isAfter()
  //     : null
  //   : false;

  let disabled = props.disabled
    ? props.disabled.seconds
      ? moment().isAfter(new Date(props.disabled.seconds * 1000))
      : props.disabled
    : false;

  const before = date
    ? props.before.seconds
      ? new Date(props.before.seconds * 1000)
      : date
    : null;

  const after = props.after
    ? date
      ? props.after.seconds
        ? new Date(props.after.seconds * 1000)
        : date
      : null
    : new Date();

  const durationTS = props.duration
    ? props.duration.seconds
      ? new Date(props.duration.seconds * 1000)
      : date
    : null;

  const duration = durationTS
    ? moment
        .duration(moment(timeDate).diff(moment(durationTS), "milliseconds"))
        .locale("de")
        .humanize()
    : false;

  // console.log(
  //   "EventDetailTime #2",
  //   props.title,
  //   " - before:",
  //   before,
  //   " - after:",
  //   after
  // );

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.title}
          </Typography>
          {!expanded && (
            <div>
              <Typography
                variant="h4"
                component="h2"
                className={classes.largeTitle}
              >
                <Moment format="dd, DD.MM.YYYY">{timeDate}</Moment>
                {" um "}
                <Moment format="HH:mm">{timeDate}</Moment>
                {" Uhr"}
              </Typography>
              {duration && (
                <Typography className={classes.pos} color="textSecondary">
                  {"⏱ Läuft "}
                  {duration}
                </Typography>
              )}
            </div>
          )}
        </CardContent>

        {!disabled && (
          <CardActions className={classes.cardAction}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Bearbeiten"
            >
              <CreateIcon />
            </IconButton>
          </CardActions>
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div className={classes.section}>
              <KeyboardDateTimePicker
                className={classes.input}
                ampm={false}
                inputVariant={"outlined"}
                format={"DD.MM.YYYY HH:mm"}
                minutesStep={5}
                value={timeDate}
                onChange={timestamp => onChange(timestamp)}
                maxDate={before}
                minDate={after}
              />
            </div>

            <div className={classes.section}>
              {error ? (
                <div className={classes.alert}>
                  <Typography>{error}</Typography>
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateHandler()}
                >
                  Ändern
                </Button>
              )}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
export default EventDetailTime;
