import React from "react";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import fire from "./config/firebase";
// import { spacing } from '@material-ui/system';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Manager from "./components/Manager";
import Code from "./components/Code";
import Event from "./components/Event";
import Loading from "./components/Loading";

import Login from "./components/Login";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Example from "./components/Example";

import "./App.css";

const firebaseAppAuth = fire.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#3c8ce7"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: "#3fb41f"
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#ffcc00',
    }
    // error: will use the default color
  }
});

function App(props) {
  const user = props.user;
  const globalProps = props;

  return (
    <Router>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <div>
            <Switch>
              <Route
                exact
                path="/code/:eventId"
                render={props => <Code {...props} user={user} />}
              />
              <Route
                render={props => (
                  <Header
                    user={user}
                    {...props}
                    {...globalProps}
                    // signOut={props.signOut}
                    // signInWithGoogle={props.signInWithGoogle}
                  />
                )}
              />
            </Switch>

            {typeof user !== "undefined" ? (
              <Container maxWidth="false" className="util-p-0">
                {user ? (
                  <div>
                    <Route
                      exact
                      path="/admin/"
                      render={props => <Example {...props} user={user} />}
                    />
                    <Route
                      exact
                      path="/manage/:eventId"
                      render={props => <Event {...props} user={user} />}
                    />
                    <Route
                      exact
                      path="/manage"
                      render={props => <Manager {...props} user={user} />}
                    />
                    <Route
                      exact
                      path="/"
                      render={props => <Manager {...props} user={user} />}
                    />
                    <Switch>
                      <Redirect
                        from="/login/:email/:eventId"
                        to="/manage/:eventId"
                      />
                      <Redirect from="/login" to="/manage" />
                    </Switch>
                  </div>
                ) : (
                  <div>
                    <Route exact path="/login/:email" component={Login} />
                    <Route
                      exact
                      path="/login/:email/:eventId"
                      component={Login}
                    />
                    <Route exact path="/login" render={props => <Login {...props} {...globalProps} />} />
                    <Route
                      exact
                      path="/"
                      render={props => <Login {...props} {...globalProps} />}
                    />
                    <Switch>
                      <Redirect from="/manage" to="/login" />
                    </Switch>
                  </div>
                )}
              </Container>
            ) : (
              <Loading />
            )}

            <Switch>
              <Route
                exact
                path="/code/:eventId"
                render={props => (

                  null
                )}
              />
              <Route
              render={props => <Footer {...props} user={user}  {...globalProps} />}
               
              />
            </Switch>

            
          </div>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </Router>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(App);
